import c3api from "@/c3api";
import { ref } from "vue";
import { useToast } from "vue-toastification";

export default function useClients() {
  const clientLoading = ref(false);

  const clients = ref([]);
  const client = ref(null);
  const users = ref(null);
  const invites = ref(null);
  const rates = ref([]);
  const clientTypes = ref([]);
  const toast = useToast();

  const fetchClients = async (urlParams = null) => {
    try {
      clientLoading.value = true;
      const response = await c3api.get("/clients", { params: urlParams });
      return (clients.value = response.data.data);
    } catch (error) {
      toast.error(error.response?.data.data.join(". "));
    } finally {
      clientLoading.value = false;
    }
  };

  const fetchClient = async (id) => {
    try {
      clientLoading.value = true;
      const response = await c3api.get(`/clients/${id}`);
      return (client.value = response.data.data);
    } catch (error) {
      toast.error(error.response?.data.data.join(". "));
    } finally {
      clientLoading.value = false;
    }
  };

  const shopifyOauthIntegration = async (params) => {
    try {
      clientLoading.value = true;
      const response = await c3api.get(`/shopify/authorize`, {
        params: params,
      });
    } catch (error) {
      toast.error(error.response?.data.data.join(". "));
    } finally {
      clientLoading.value = false;
    }
  };

  const fetchRates = async (id) => {
    try {
      clientLoading.value = true;
      const response = await c3api.get(`/clients/${id}/rates`);
      return (rates.value = response.data.data);
    } catch (error) {
      toast.error(error.response?.data.data.join(". "));
    } finally {
      clientLoading.value = false;
    }
  };

  const wooCommerceCreateApiUser = async (params) => {
    try {
      clientLoading.value = true;
      const response = await c3api.post(`/woocommerce/api_users`, params);
      toast.success("API user created successfully");
      return response.data.data;
    } catch (error) {
      toast.error(error.response?.data.data.join(". "));
    } finally {
      clientLoading.value = false;
    }
  };

  const fetchClientTypes = async () => {
    try {
      clientLoading.value = true;
      const response = await c3api.get(`/clients/client_types`);
      return (clientTypes.value = response.data.data);
    } catch (error) {
      toast.error(error.response?.data.data.join(". "));
    } finally {
      clientLoading.value = false;
    }
  };

  const fetchClientUsers = async (id) => {
    try {
      clientLoading.value = true;
      const response = await c3api.get(`clients/${id}/users`);
      return (users.value = response.data.data);
    } catch (error) {
      toast.error(error.response.data?.data?.join(". "));
    } finally {
      clientLoading.value = false;
    }
  }

  const updateClient = async (id, params) => {
    try {
      clientLoading.value = true;
      const response = await c3api.patch(`/clients/${id}`, params);
      return response.data.data;
    } catch (error) {
      toast.error(error.response?.data?.data.join(". "));
    } finally {
      clientLoading.value = false;
    }
  }

  const toggleUserStatus = async (id, params) => {
    try {
      clientLoading.value = true;
      const response = await c3api.patch(`/users/${id}`, params);
      return response.data.data;
    } catch (error) {
      toast.error(error.response.data?.data?.join(". "));
    } finally {
      clientLoading.value = false;
    }
  }

  const deleteClientUser = async(id) => {
    try {
      clientLoading.value = true;
      const response = await c3api.delete(`/users/${id}`);
      toast.success("User deleted successfully.");
      return response.data.data;
    } catch (error) {
      toast.error(error.response.data.data.join(". "));
    } finally {
      clientLoading.value = false;
    }
  };

  const fetchClientInvites = async (id) => {
    try {
      clientLoading.value = true;
      const response = await c3api.get(`/clients/${id}/invitations`);
      invites.value = response.data.data.filter((invite) => invite.redeemed === false);
    } catch (error) {
      toast.error(error.response.data.data.join(". "));
    } finally {
      clientLoading.value = false;
    }
  };

  const deleteClientInvite = async (clientId, id) => {
    try {
      const response = await c3api.delete(`/clients/${clientId}/invitations/${id}`);
      toast.success("Invite deleted successfully.");
      return response;
    } catch (error) {
      toast.error(error.response.data.data.join(". "));
    } finally {
      clientLoading.value = false;
    }
  };

  const sendClientInvite = async (clientId, params) => {
    try {
      const response = await c3api.post(`/clients/${clientId}/invitations`, params);
      toast.success("Invite sent successfully.");
      return response.data.data;
    } catch (error) {
      toast.error(error.response.data.data.join(". "));
    } finally {
      clientLoading.value = false;
    }
  };

  const addWebhooks = async (id, params) => {
    try {
      clientLoading.value = true;
      const response = await c3api.post(`/clients/${id}/webhooks`, params);
      return response.data.data;
    } catch (error) {
      toast.error(error.response.data?.data?.join(". "));
    } finally {
      clientLoading.value = false;
    }
  }

  const deleteWebhooks = async (id, webhookId) => {
    try {
      clientLoading.value = true;
      const response = await c3api.delete(`/clients/${id}/webhooks/${webhookId}`);
      return response.data.data;
    } catch (error) {
      toast.error(error.response.data?.data?.join(". "));
    } finally {
      clientLoading.value = false;
    }
  }

  const toggleWebhookStatus = async (id, webhookId, params) => {
    try {
      clientLoading.value = true;
      const response = await c3api.post(`/clients/${id}/webhooks/${webhookId}`, params);
      return response.data.data;
    } catch (error) {
      toast.error(error.response.data?.data?.join(". "));
    } finally {
      clientLoading.value = false;
    }
  }

  const toggleNotifications = async (id, params) => {
    try {
      clientLoading.value = true;
      const response = await c3api.patch(`/clients/${id}/notifications`, params);
      return response.data.data;
    } catch (error) {
      toast.error(error.response.data?.data?.join(". "));
    } finally {
      clientLoading.value = false;
    }
  }

  return {
    clients,
    clientLoading,
    fetchClients,
    client,
    fetchClient,
    rates,
    fetchRates,
    clientTypes,
    fetchClientTypes,
    users,
    fetchClientUsers,
    updateClient,
    shopifyOauthIntegration,
    wooCommerceCreateApiUser,
    toggleUserStatus,
    addWebhooks,
    deleteWebhooks,
    toggleWebhookStatus,
    toggleNotifications,
    deleteClientUser,
    fetchClientInvites,
    invites,
    deleteClientInvite,
    sendClientInvite,
  };
}
